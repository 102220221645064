import {EventEmitter, Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  public vendorProductObservable = new BehaviorSubject(null);

  constructor(
      private restangular: Restangular
  ) {
  }

  getProductLaunchMetadata(vendorProductId): Promise<any> {
    return this.restangular.one('vendors', vendorProductId).one('launchmetadata').get().toPromise();
  }

  getProductLaunchUrl(vendor, product, context): Promise<any> {
    return this.restangular.one('vendors', vendor).one('launchurl', product || 'no_product').customPUT(context || 'no_context').toPromise();
  }

  enrollProduct(vendor, product, context): Promise<any> {
    return this.restangular.one('vendors', vendor).one('enroll', product || 'no_product').customPUT(context || 'no_context').toPromise();
  }

  updateProduct(vendor, product, context): Promise<any> {
    return this.restangular.one('vendors', vendor).one('update', product || 'no_product').customPUT(context || 'no_context').toPromise();
  }

  cancelProductEnrollment(vendor, product, context): Promise<any> {
    return this.restangular.one('vendors', vendor).one('cancelenrollment', product || 'no_product').customPUT(context || 'no_context').toPromise();
  }

  getVendorProduct(vendorProductId: string): Promise<any> {
    return this.restangular.one('vendors').one('product', vendorProductId).get().toPromise().then(result => {
      this.vendorProductObservable.next(result);
      return result;
    });
  }

  getAllVendorProducts(): Promise<any> {
    return this.restangular.one('vendors').one('products', 'all').get().toPromise();
  }

  getVendorProductsWithUpdate(): Promise<any> {
    return this.restangular.one('vendors').one('products', 'updates').get().toPromise();
  }

}
