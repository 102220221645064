import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Router} from '@angular/router';
import {ModalController, Platform} from '@ionic/angular';
import {UtilsService} from '../../services/utils/utils.service';
import {SessionService} from '../../services/session/session.service';
import {UsersService} from '../../services/users/users.service';
import {RadiusService} from '../../services/radius/radius.service';
import {BrandsService} from '../../services/brands/brands.service';
import {LocationService} from '../../services/location/location.service';
import {AdContext} from '../../components/integration-ad-carousel/integration-ad-carousel.component';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit {

  @ViewChild('searchbar') searchbar;

  protected myBalance;
  protected bandsConfig = [];
  protected adc = AdContext;
  protected PRODUCTS_ONLY = this.brandsService.getUIConfig().productsOnly;

  constructor(
      private platform: Platform,
      private modalCtrl: ModalController,
      private router: Router,
      private sessionService: SessionService,
      private utilsService: UtilsService,
      private usersService: UsersService,
      private radiusService: RadiusService,
      apiService: ApiService,
      private brandsService: BrandsService,
      private locationService: LocationService
  ) {
  }

  ngOnInit() {
    this.loadBands();
    this.usersService.meSubscribe((data) => {
      if (data) {
        this.loadBands();
      }
    });
  }

  private loadBands() {
    this.bandsConfig = this.brandsService.getConfigJSON().bands;
  }

  doRefresh(event) {
    this.bandsConfig = JSON.parse(JSON.stringify(this.bandsConfig));
    setTimeout(() => event.target.complete(), 1000);
  }

  doSearch() {
    if (this.searchbar.value) {
      this.locationService.here().then(location => {
        this.router.navigate(['/rebatelist', this.searchbar.value, location.latitude, location.longitude, '25']);
      });
    }
  }
}
