import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {Platform} from '@ionic/angular';
// import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {DomSanitizer} from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import QRCode from 'qrcode';

@Component({
  selector: 'app-showqrcode',
  templateUrl: './showqrcode.component.html',
  styleUrls: ['./showqrcode.component.scss'],
})
export class ShowqrcodeComponent implements OnInit {

  @ViewChild('canvas') canvas;
  @Input() json: any;
  protected imageUrl;

  constructor(
      private platform: Platform,
      private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
      /*
      if (this.platform.is('cordova')) {
          this.barcodeScanner
              .encode(this.barcodeScanner.Encode.TEXT_TYPE, JSON.stringify(this.json))
              .then((encodedData) => {
                  this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(Capacitor.convertFileSrc(encodedData.file));
              }, (err) => {
                  console.log('scan error: ' + err);
              });
      }
      */
      QRCode.toDataURL(JSON.stringify(this.json), function (error, url) {
          if (error) {
              console.log(error);
          } else {
              this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
          }
      }.bind(this));
  }

}
