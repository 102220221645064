import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurlyConfirmComponent } from './modaldialog/confirm/confirm.component';
import { OurlyUserIconComponent } from './user-icon/user-icon.component';
import { OurlyUserBalanceComponent } from './user-balance/user-balance.component';
import { OurlyUserCarouselComponent } from './user-carousel/user-carousel.component';
import { UserAccountsRadiusComponent } from './bank-providers/radius/user-accounts-radius/user-accounts-radius.component';
import { OurlyUserMyFamilyComponent } from './user-my-family/user-my-family.component';
import { UserMyTasksComponent } from './user-my-tasks/user-my-tasks.component';
import {OurlyItemDetailComponent} from './item-detail/item-detail.component';
import {OurlySetupContentComponent} from './setup-content/setup-content.component';
import {OurlyHeaderSetupComponent} from './header-setup/header-setup.component';
import {OurlyFormItemComponent} from './form-item/form-item.component';
import {OurlyAddFamilyComponent} from './add-family/add-family.component';
import { SpendingSavingsChartComponent } from './charts/spending-savings-chart/spending-savings-chart.component';
import { OurlyBankComponent } from './bank/bank.component';
import { OurlyTransactionComponent } from './transaction/transaction.component';
import { OurlyIconComponent} from './icon/icon';
import { PipesModule} from '../pipes/pipes.module';
import { OurlyHeaderComponent } from './header/header.component';
import { OurlyChooseComponent } from './choose/choose.component';
import { ToastComponent } from './toast/toast.component';
import { YesNoComponent } from './yes-no/yes-no.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import { OurlyUserMyGoalsComponent } from './user-my-goals/user-my-goals.component';
import { GetFundingAmountComponent } from './fields/get-funding-amount/get-funding-amount.component';
import { OurlyGoalComponent } from './goal/goal.component';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { GetEmailComponent } from './fields/get-email/get-email.component';
import { FieldbaseComponent } from './fields/fieldbase/fieldbase.component';
import { GetAmountComponent } from './fields/get-amount/get-amount.component';
import { HomePagePopupComponent } from './home-page-popup/home-page-popup.component';
import {ContextHelpComponent} from './context-help/context-help.component';
import { PendingTransfersComponent } from './pending-transfers/pending-transfers.component';
import {BalanceCardComponent} from './balance-card/balance-card.component';
import { NameFieldComponent } from './form-fields/name-field/name-field.component';
import { EmailFieldComponent } from './form-fields/email-field/email-field.component';
import { TitleFieldComponent } from './form-fields/title-field/title-field.component';
import { DescriptionFieldComponent } from './form-fields/description-field/description-field.component';
import { StateFieldComponent } from './form-fields/state-field/state-field.component';
import { PostalFieldComponent } from './form-fields/postal-field/postal-field.component';
import { AddressFieldComponent } from './form-fields/address-field/address-field.component';
import { DobFieldComponent } from './form-fields/dob-field/dob-field.component';
import {GoalItemComponent} from './goal-item/goal-item.component';
import {GoaldateFieldComponent} from './form-fields/goaldate-field/goaldate-field.component';
import {PhoneFieldComponent} from './form-fields/phone-field/phone-field.component';
import {RecurringTaskFieldComponent} from './form-fields/recurring-task-field/recurring-task-field.component';
import {OnlyNumbersDirective} from '../directives/only-numbers.directive';
import {BrMaskerModule} from 'br-mask';
import { AmountFieldComponent } from './form-fields/amount-field/amount-field.component';
import {MonthlySpendingChartComponent} from './charts/monthly-spending-chart/monthly-spending-chart.component';
import {TakePhotoComponent} from './take-photo/take-photo.component';
import {TaskInfoComponent} from './task-info/task-info.component';
import {GoalCompletionBarComponent} from './goal-completion-bar/goal-completion-bar.component';
import {HelpComponent} from './help/help.component';
import {ResetMagicCodeComponent} from './modaldialog/reset-magic-code/reset-magic-code.component';
import {MagicCodeSentComponent} from './modaldialog/magic-code-sent/magic-code-sent.component';
import {SsnFieldComponent} from './form-fields/ssn-field/ssn-field.component';
import {DateComponent} from './date/date.component';
import {CityFieldComponent} from './form-fields/city-field/city-field.component';
import {GetDateComponent} from './fields/get-date/get-date.component';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import {DatepickerFieldComponent} from './form-fields/datepicker-field/datepicker-field.component';
import { AllowanceFieldComponent } from './form-fields/allowance-field/allowance-field.component';
import { EarnedKeptChartComponent } from './charts/earned-kept-chart/earned-kept-chart.component';
import { DobPickerComponent } from './form-fields/dob-picker/dob-picker.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {ContentComponent} from './content/content.component';
import {GlideComponent} from './glide/glide.component';
import {TelFieldComponent} from './form-fields/tel-field/tel-field.component';
import {SnacksComponent} from './snacks/snacks.component';
import {ClaimrewardComponent} from './modaldialog/claimreward/claimreward.component';
import {SmartBalanceCardComponent} from './smart-balance-card/smart-balance-card.component';
import { FormfieldbaseComponent } from './form-fields/formfieldbase/formfieldbase.component';
import {HeaderHomeComponent} from './header-home/header-home.component';
import {HeaderLoginComponent} from './header-login/header-login.component';
import {RebatesCarouselComponent} from './rebates-carousel/rebates-carousel.component';
import {LockbuttonComponent} from './lockbutton/lockbutton.component';
import {FloatingintroComponent} from './floatingintro/floatingintro.component';
import {BaseComponent} from './base/base.component';
import {CashbackComponent} from './cashback/cashback.component';
import {QueryusersComponent} from './queryusers/queryusers.component';
import {ShowqrcodeComponent} from './showqrcode/showqrcode.component';
import {QrScannerComponent} from 'angular2-qrscanner';
import {UserItemComponent} from './user-item/user-item.component';
import {RebatesMapComponent} from './rebates-map/rebates-map.component';
import {RebatesListComponent} from './rebates-list/rebates-list.component';
import {P2pactivityComponent} from './p2pactivity/p2pactivity.component';
import {AccountpickerComponent} from './accountpicker/accountpicker.component';
import {CjCarouselComponent} from './cj-carousel/cj-carousel.component';
import {SetsharegoalheaderComponent} from './modaldialog/setsharegoalheader/setsharegoalheader.component';
import {SetfrequencyComponent} from './modaldialog/setfrequency/setfrequency.component';
import {SetKeywordsComponent} from './modaldialog/set-keywords/set-keywords.component';
import {AddAccountReminderComponent} from './add-account-reminder/add-account-reminder.component';
import {UserAccountsDwollaComponent} from './bank-providers/dwolla/user-accounts-dwolla/user-accounts-dwolla.component';
import {UserAccountsComponent} from './bank-providers/user-accounts/user-accounts.component';
import {SetAdvertisersComponent} from './modaldialog/set-advertisers/set-advertisers.component';
import {AdvertiserCarouselComponent} from './advertiser-carousel/advertiser-carousel.component';
import {HomeCtaComponent} from './homecta/homecta.component';
import {HeaderTeamComponent} from './header-team/header-team.component';
import {TeamInfoCardComponent} from './team-info-card/team-info-card.component';
import {FileuploadComponent} from './fileupload/fileupload.component';
import {TeamMemberSelectorComponent} from './team-member-selector/team-member-selector.component';
// import {TooltipModule} from 'ng2-tooltip-directive';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {InfopopupComponent} from './infopopup/infopopup.component';
import {ReimbursementsComponent} from './reimbursements/reimbursements.component';
import {TransferHistoryComponent} from './transfer-history/transfer-history.page';
import {TeamCarouselComponent} from './team-carousel/team-carousel.component';
import {RebatesCategoriesComponent} from './rebates-categories/rebates-categories.component';
import {RebatesApiComponent} from './rebates-api/rebates-api.component';
import {IntegrationAdCarouselComponent} from './integration-ad-carousel/integration-ad-carousel.component';
import {AchErrorCodesComponent} from './modaldialog/acherrorcodes/acherrorcodes.component';
import {SetteamComponent} from './modaldialog/setteam/setteam.component';
import {ExploreComponent} from './explore/explore.component';
import {InsuranceDependentsComponent} from './insurance-dependents/insurance-dependents.component';
import {InsurancePrimaryComponent} from './insurance-primary/insurance-primary.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import {InsuranceBeneficiariesComponent} from './insurance-beneficiaries/insurance-beneficiaries.component';
import {MatInputModule} from '@angular/material/input';
import {VideoCarouselComponent} from './video-carousel/video-carousel.component';

@NgModule({
  declarations: [OurlyConfirmComponent, OurlyUserIconComponent, OurlyUserBalanceComponent, OurlyUserCarouselComponent,
    UserAccountsRadiusComponent, OurlyUserMyFamilyComponent, UserMyTasksComponent, OurlyItemDetailComponent,
    OurlySetupContentComponent, OurlyHeaderSetupComponent, OurlyFormItemComponent, OurlyAddFamilyComponent, SpendingSavingsChartComponent,
    OurlyBankComponent, OurlyTransactionComponent, OurlyIconComponent, OurlyHeaderComponent, GoalCompletionBarComponent,
    OurlyChooseComponent, ToastComponent, MonthlySpendingChartComponent, TakePhotoComponent, TaskInfoComponent,
    YesNoComponent, OurlyUserMyGoalsComponent, GetFundingAmountComponent, OurlyGoalComponent,
    PasswordStrengthBarComponent, GetEmailComponent, FieldbaseComponent, FormfieldbaseComponent, GetAmountComponent, HomePagePopupComponent, ContextHelpComponent,
    PendingTransfersComponent, BalanceCardComponent, HelpComponent, ResetMagicCodeComponent, MagicCodeSentComponent,
    DobFieldComponent,
    GoalItemComponent,
    NameFieldComponent,
    EmailFieldComponent,
    TitleFieldComponent,
    DescriptionFieldComponent,
    StateFieldComponent,
    PostalFieldComponent,
    AddressFieldComponent,
    GoaldateFieldComponent,
    PhoneFieldComponent,
    RecurringTaskFieldComponent,
    OnlyNumbersDirective,
    AmountFieldComponent,
    SsnFieldComponent,
    DateComponent,
    CityFieldComponent,
    GetDateComponent,
    DatepickerFieldComponent,
    AllowanceFieldComponent,
    EarnedKeptChartComponent,
    DobPickerComponent,
    ToolbarComponent,
    ContentComponent,
    GlideComponent,
    TelFieldComponent,
    SnacksComponent,
    ClaimrewardComponent,
    SmartBalanceCardComponent,
    ClaimrewardComponent,
    HeaderHomeComponent,
    HeaderLoginComponent,
    HeaderTeamComponent,
    RebatesCarouselComponent,
    LockbuttonComponent,
    FloatingintroComponent,
    BaseComponent,
    CashbackComponent,
    QueryusersComponent,
    ShowqrcodeComponent,
    QrScannerComponent,
    UserItemComponent,
    RebatesMapComponent,
    RebatesListComponent,
    RebatesCategoriesComponent,
    P2pactivityComponent,
    AccountpickerComponent,
    CjCarouselComponent,
    AdvertiserCarouselComponent,
    SetsharegoalheaderComponent,
    SetfrequencyComponent,
    SetKeywordsComponent,
    SetAdvertisersComponent,
    AddAccountReminderComponent,
    UserAccountsDwollaComponent,
    UserAccountsComponent,
    HomeCtaComponent,
    TeamInfoCardComponent,
    FileuploadComponent,
    TeamMemberSelectorComponent,
    InfopopupComponent,
    ReimbursementsComponent,
    TransferHistoryComponent,
    TeamCarouselComponent,
    RebatesApiComponent,
    IntegrationAdCarouselComponent,
    SetteamComponent,
    ExploreComponent,
    InsuranceDependentsComponent,
    InsurancePrimaryComponent,
    InsuranceBeneficiariesComponent,
    VideoCarouselComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    BrMaskerModule,
    Ionic4DatepickerModule,
    // TooltipModule,
    SharedDirectivesModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatTabsModule,
  ],
  exports: [OurlyConfirmComponent, OurlyUserIconComponent, OurlyUserBalanceComponent, OurlyUserCarouselComponent,
    UserAccountsRadiusComponent, OurlyUserMyFamilyComponent, UserMyTasksComponent, OurlyItemDetailComponent,
    OurlySetupContentComponent, OurlyHeaderSetupComponent, OurlyFormItemComponent, OurlyAddFamilyComponent, SpendingSavingsChartComponent,
    OurlyBankComponent, OurlyTransactionComponent, OurlyIconComponent, OurlyHeaderComponent, GoalCompletionBarComponent,
    OurlyChooseComponent, ToastComponent, MonthlySpendingChartComponent, TakePhotoComponent, TaskInfoComponent,
    OurlyUserMyGoalsComponent, GetFundingAmountComponent, OurlyGoalComponent,
    PasswordStrengthBarComponent, GetEmailComponent, FieldbaseComponent, FormfieldbaseComponent, GetAmountComponent, HomePagePopupComponent, ContextHelpComponent,
    PendingTransfersComponent, PipesModule , BalanceCardComponent,
    HelpComponent, ResetMagicCodeComponent, MagicCodeSentComponent,
    DobFieldComponent,
    GoalItemComponent,
    NameFieldComponent,
    EmailFieldComponent,
    TitleFieldComponent,
    DescriptionFieldComponent,
    StateFieldComponent,
    PostalFieldComponent,
    AddressFieldComponent,
    GoaldateFieldComponent,
    PhoneFieldComponent,
    RecurringTaskFieldComponent,
    OnlyNumbersDirective,
    AmountFieldComponent,
    SsnFieldComponent,
    DateComponent,
    CityFieldComponent,
    GetDateComponent,
    DatepickerFieldComponent,
    AllowanceFieldComponent,
    EarnedKeptChartComponent,
    DobPickerComponent,
    ToolbarComponent,
    ContentComponent,
    GlideComponent,
    TelFieldComponent,
    SnacksComponent,
    ClaimrewardComponent,
    SmartBalanceCardComponent,
    HeaderHomeComponent,
    HeaderLoginComponent,
    HeaderTeamComponent,
    RebatesCarouselComponent,
    LockbuttonComponent,
    FloatingintroComponent,
    BaseComponent,
    CashbackComponent,
    QueryusersComponent,
    ShowqrcodeComponent,
    QrScannerComponent,
    UserItemComponent,
    RebatesMapComponent,
    RebatesListComponent,
    RebatesCategoriesComponent,
    P2pactivityComponent,
    AccountpickerComponent,
    CjCarouselComponent,
    AdvertiserCarouselComponent,
    SetsharegoalheaderComponent,
    SetfrequencyComponent,
    SetKeywordsComponent,
    SetAdvertisersComponent,
    AddAccountReminderComponent,
    UserAccountsDwollaComponent,
    UserAccountsComponent,
    HomeCtaComponent,
    TeamInfoCardComponent,
    FileuploadComponent,
    TeamMemberSelectorComponent,
    InfopopupComponent,
    ReimbursementsComponent,
    TransferHistoryComponent,
    TeamCarouselComponent,
    RebatesApiComponent,
    IntegrationAdCarouselComponent,
    SetteamComponent,
    ExploreComponent,
    InsuranceDependentsComponent,
    InsurancePrimaryComponent,
    InsuranceBeneficiariesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [OurlyConfirmComponent, OurlyChooseComponent, HomePagePopupComponent, HelpComponent ],
  providers: [
  ]
})
export class ComponentsModule { }
